export const META_DESCRIPTION = {
	store: 'Discover our innovative biohacking solutions for your health and wellness goals. Try our convenient Snap servings to amplify your life!',
	product_detail_bran: 'Boost body & brain with brān®, a well-rounded nootropic. Experience clean, lasting energy, improved focus, and enhanced mood. Shop now for $99.95 USD.',
	product_detail_zlem: 'Renew your sleep naturally with zlēm® - a two-stage formula for restful nights and healthy weight management. Enjoy improved sleep quality. Shop now $109.95.',
	product_detail_uuth: 'Reclaim your youth with uüth® Time Reverser Superberry geleé. Experience firmer skin, reduced fine lines, and improved vitality. Shop now for $119.95 USD.',
	product_detail_plos: 'Enhance your fitness journey with plôs® Thermo - the powerful Snap for healthy weight management and improved metabolism. Shop now for $119.95 USD.',
	product_detail_byom: 'Nourish your gut health with byōm® - a harmonizing dietary supplement. Balance pH levels, improve digestion, and hydrate optimally. Shop now at $119.95 USD.',
	product_detail_tuun: 'Experience the power of tuün® Resonate - a wearable biohack designed to protect you from harmful electromagnetic fields and enhance your well-being.',
	landing_page_bran: 'Learn more about brān® - a premium nootropic serum with 16 nutrients to fuel your brain and boost mental energy. Enhance focus, mood, and mental acuity.',
	landing_page_zlem: 'Learn more about zlēm® sleep & slim - a cutting-edge biohacking formula for restful sleep and healthy weight management. Enhance mornings and boost your AWESOME',
	landing_page_uuth: 'Learn more about uüth® time reverser - a premium gelée for vibrant, youthful living. Promote youthful skin, boost nitric oxide, and feel energized.',
	landing_page_plos: 'Learn more about plôs® Thermo - a non-dairy creamer designed to support healthy weight management and boost metabolism. Achieve your fitness dreams with plôs®!',
	landing_page_tuun: 'Learn more about tuün® Resonate - a state-of-the-art wearable biohack designed to counter EMF radiation. Improve sleep, focus, and well-being with B.E.T.',
};

export default {};
